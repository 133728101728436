<template>
	<div class="popupVideo-Block" :class="{ closing: closing }" @click.self="closePopup()">
		<div class="inner">
			<div class="video">
				<div class="closeButton" @click="closePopup()">
					<img src="/images/UPS_Cross.svg" alt="" />
				</div>
				<video controls controlsList="nodownload" :poster="$props.videoURL.replace('mp4', 'jpg')">
					<source :src="$props.videoURL" type="video/mp4" />
				</video>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
	name: "Popup Video Block",
	props: {
		videoURL: String,
	},
	setup() {
		const store = useStore();

		/* ----- Data -------------------- */
		const closing = ref(false);

		/* ----- Methods -------------------- */
		const closePopup = () => {
			closing.value = true;

			setTimeout(() => {
				store.commit("popupVideo/updatePopup", {
					status: "close",
				});
			}, 1500);
		};

		return {
			closing,
			closePopup,
		};
	},
};
</script>

<style lang="scss" scoped>
.popupVideo-Block {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 0 12.5%;
	z-index: 8;
	cursor: zoom-out;

	.inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 50%;
		cursor: auto;

		.video {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			height: 0px;
			padding-top: 56.25%;
			overflow-y: hidden;

			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				opacity: 0;

				animation-name: video;
				animation-iteration-count: 1;
				animation-duration: 0.6s;
				animation-delay: 0s;
				animation-fill-mode: forwards;
			}
			.closeButton {
				position: absolute;
				top: 15px;
				right: 15px;
				width: 45px;
				height: 45px;
				z-index: 1;
				cursor: pointer;

				opacity: 0;

				animation-name: video;
				animation-iteration-count: 1;
				animation-duration: 0.6s;
				animation-delay: 0s;
				animation-fill-mode: forwards;
			}
		}
		.video:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--hive);
			z-index: 2;

			top: 100%;

			animation-name: videoOverlay;
			animation-iteration-count: 1;
			animation-duration: 1.2s;
			animation-delay: 0s;
			animation-fill-mode: forwards;
		}
	}
}
.popupVideo-Block.closing {
	animation-name: popupClosing;
	animation-iteration-count: 1;
	animation-duration: 0.3s;
	animation-delay: 1.2s;
	animation-fill-mode: forwards;

	.inner {
		.video {
			.closeButton {
				animation-name: videoClosing;
				animation-iteration-count: 1;
				animation-duration: 0.6s;
				animation-delay: 0s;
				animation-fill-mode: forwards;
			}
			video {
				animation-name: videoClosing;
				animation-iteration-count: 1;
				animation-duration: 0.6s;
				animation-delay: 0s;
				animation-fill-mode: forwards;
			}
		}
		.video:after {
			animation-name: videoOverlayClosing;
			animation-iteration-count: 1;
			animation-duration: 1.2s;
			animation-delay: 0s;
			animation-fill-mode: forwards;
		}
	}
}
@media only screen and (max-width: 768px) {
	.popupVideo-Block {
		.inner {
			width: 90%;
			height: 0px;

			.video {
				.closeButton {
					position: absolute;
					top: 10px;
					right: 10px;
					width: 30px;
					height: 30px;
					z-index: 5;
					cursor: pointer;
				}
			}
		}
	}
}

/* ----- Animations -------------------- */
@keyframes videoOverlay {
	0% {
		top: 100%;
	}
	50% {
		top: 0;
	}
	100% {
		top: -100%;
	}
}
@keyframes video {
	0% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes popupClosing {
	0% {
		background-color: rgba(0, 0, 0, 0.5);
	}
	100% {
		background-color: rgba(0, 0, 0, 0);
	}
}
@keyframes videoOverlayClosing {
	0% {
		top: -100%;
	}
	50% {
		top: 0;
	}
	100% {
		top: 100%;
	}
}
@keyframes videoClosing {
	0% {
		opacity: 1;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
