<template>
	<div class="header-Block" v-if="loaded">
		<img v-if="$props.content.fileURL" :src="$props.content.fileURL" alt="" />
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="pushIn">
							<h1>{{ $props.content.title }}</h1>
							<p>{{ $props.content.text }}</p>
							<div v-if="$props.content.button">
								<a v-if="!$props.content.homePage" :href="$props.content.buttonURL">
									<button>{{ $props.content.button }}</button>
								</a>
								<button v-else @click="openPopup()">{{ content.button }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="scrollDown" :class="{ color: $props.content.pageColor }" @click="scroll()">
			<div class="arrow"></div>
		</div>
		<div
			class="colorBlock"
			v-if="$props.content.pageColor"
			:style="'background-color:' + $props.content.pageColor + ';'"
		></div>
	</div>
	<PopupVideoBlock :videoURL="content.buttonURL" v-if="popup" />
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import PopupVideoBlock from "@/components/blocks/popupVideo";

export default {
	name: "Header Block",
	components: {
		PopupVideoBlock,
	},
	props: {
		content: Object,
	},
	setup(props) {
		const store = useStore();

		/* ----- Data -------------------- */
		const loaded = ref(false);
		const popup = ref(computed(() => store.state.popupVideo.data.popup));

		/* ----- Methods -------------------- */
		const openPopup = () => {
			store.commit("popupVideo/updatePopup", {
				status: "openVideo",
				videoURL: props.content.buttonURL,
			});
		};
		const scroll = () => {
			window.scrollTo(0, window.innerHeight);
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;
		});

		return {
			loaded,
			popup,
			openPopup,
			scroll,
		};
	},
};
</script>

<style lang="scss" scoped>
.header-Block {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		align-content: center;
		z-index: 1;

		h1 {
			font-size: 100px;
			font-weight: 900;
			color: var(--background);
			margin: 0;
			margin-bottom: 30px;
			text-transform: uppercase;
			letter-spacing: -3px;

			transform: translateY(50px);
			opacity: 0;

			animation-name: h1;
			animation-iteration-count: 1;
			animation-duration: 0.8s;
			animation-delay: 1.4s;
			animation-fill-mode: forwards;
		}
		p {
			font-size: 20px;
			font-weight: 400;
			color: var(--background);
			margin: 0;
			margin-bottom: 60px;

			transform: translateY(37.5px);
			opacity: 0;

			animation-name: p;
			animation-iteration-count: 1;
			animation-duration: 0.8s;
			animation-delay: 1.6s;
			animation-fill-mode: forwards;
		}
		button {
			transform: translateY(37.5px);
			opacity: 0;

			animation-name: button;
			animation-iteration-count: 1;
			animation-duration: 0.8s;
			animation-delay: 1.6s;
			animation-fill-mode: forwards;
		}
	}
	.scrollDown {
		position: absolute;
		left: 0;
		right: 0;
		bottom: -60px;
		margin: auto;
		width: 120px;
		height: 120px;
		border-radius: 50% 50% 0 0;
		background-color: var(--background);
		cursor: pointer;
		z-index: 5;

		.arrow {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 50%;
			margin: auto;
			width: 30px;
			height: 30px;
			border: solid black;
			border-width: 0 3px 3px 0;
			display: inline-block;
			padding: 3px;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
	}
	.colorBlock {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 20px;
		z-index: 4;
	}
}
.header-Block:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--background-dark);
	opacity: 0.4;
}
@media only screen and (max-width: 768px) {
	.header-Block {
		.content {
			top: 30vh;

			h1 {
				font-size: 32px;
				margin-bottom: 15px;
			}
			p {
				font-size: 16px;
				margin-bottom: 30px;
			}
			button {
				width: 100%;
				padding: 10px 15px;
				text-align: center;
				font-size: 18px;
			}
		}
	}
}

/* ----- Animations -------------------- */
@keyframes h1 {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes p {
	0% {
		transform: translateY(37.5px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes button {
	0% {
		transform: translateY(37.5px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
