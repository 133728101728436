<template>
	<div class="slider-Block" :class="{ animate: animate }" :id="'slider-Block-' + id" v-if="loaded">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="pushIn">
						<h2>HIVE Zones</h2>
					</div>
				</div>
				<div class="col-12 d-none d-md-block">
					<div class="pushIn">
						<swiper :slides-per-view="4" :space-between="30" :allowTouchMove="false">
							<swiper-slide v-for="item in singleSlides.firstSlide" :key="item.id">
								<a :href="item.link">
									<div class="singleSlide" v-if="item.sliderImage">
										<img :src="item.sliderImage.fileURL" :alt="item.sliderImage.name" />
										<div class="content">
											<h4>{{ item.name }}</h4>
										</div>
									</div>
								</a>
							</swiper-slide>
							<swiper-slide v-for="item in singleSlides.secondSlide" :key="item.id">
								<a :href="item.link">
									<div class="singleSlide" v-if="item.sliderImage">
										<img :src="item.sliderImage.fileURL" :alt="item.sliderImage.name" />
										<div class="content">
											<h4>{{ item.name }}</h4>
										</div>
									</div>
								</a>
							</swiper-slide>
						</swiper>
						<div class="swiper-button-prev" @click="toggleSlide('previous')"></div>
						<div class="swiper-button-next" @click="toggleSlide('next')"></div>
					</div>
				</div>
				<div class="col-12 d-block d-md-none">
					<div class="pushIn">
						<swiper :slides-per-view="1.1" :space-between="15">
							<swiper-slide v-for="item in singleSlides.firstSlide" :key="item.id">
								<a :href="item.link">
									<div class="singleSlide" v-if="item.sliderImage">
										<img :src="item.sliderImage.fileURL" :alt="item.sliderImage.name" />
										<div class="content">
											<h4>{{ item.name }}</h4>
										</div>
									</div>
								</a>
							</swiper-slide>
							<swiper-slide v-for="item in singleSlides.secondSlide" :key="item.id">
								<a :href="item.link">
									<div class="singleSlide" v-if="item.sliderImage">
										<img :src="item.sliderImage.fileURL" :alt="item.sliderImage.name" />
										<div class="content">
											<h4>{{ item.name }}</h4>
										</div>
									</div>
								</a>
							</swiper-slide>
						</swiper>
						<div class="swiper-button-prev" @click="toggleSlide('previous')"></div>
						<div class="swiper-button-next" @click="toggleSlide('next')"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.scss";
// import 'swiper/css/navigation';

export default {
	name: "Slider Block",
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		content: Object,
	},
	setup() {
		const store = useStore();
		/* ----- Data -------------------- */
		const loaded = ref(false);
		const animate = ref(false);
		const id = Math.floor(Math.random() * (999 - 1) + 1);
		const navigation = ref(computed(() => store.state.navigation.menu));
		const singleSlides = ref(computed(() => store.state.navigation));
		console.log(singleSlides);

		/* ----- Methods -------------------- */
		const checkAnimation = () => {
			if (
				document.getElementById("slider-Block-" + id).offsetTop - window.screen.height / 1.5 <
				window.scrollY
			) {
				animate.value = true;
			}
		};
		const toggleSlide = (type) => {
			var swiperModule = document.querySelector(".swiper-container").swiper;

			if (type === "next") {
				swiperModule.slideNext();
			} else {
				swiperModule.slidePrev();
			}
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;

			document.addEventListener("DOMContentLoaded", () => {
				checkAnimation();
			});
			document.addEventListener("scroll", () => {
				checkAnimation();
			});
		});

		return {
			loaded,
			animate,
			id,
			singleSlides,
			toggleSlide,
			navigation,
		};
	},
};
</script>

<style lang="scss" scoped>
.slider-Block {
	padding: 200px 0;

	.pushIn {
		position: relative;

		h2 {
			font-size: 40px;
			font-weight: 700;
			color: var(--text-);
			margin: 0;
			margin-bottom: 45px;

			transform: translateY(50px);
			opacity: 0;
		}

		.singleSlide {
			position: relative;
			width: 100%;
			height: 0px;
			padding-top: 150%;
			background-color: var(--background-dark);
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 1;
				opacity: 0.6;
			}
			.content {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 30px;
				z-index: 2;

				h4 {
					font-size: 22px;
					font-weight: 700;
					color: var(--background);
					margin: 0;
				}
			}
		}
		.singleSlide:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--hive);
			transition: 0.6s ease;
			opacity: 0;
			z-index: 1;
		}
		.singleSlide:hover:before {
			opacity: 0.7;
		}
		.singleSlide:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--hive);
			z-index: 3;
		}
		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 60px;
			height: 60px;
			background-color: var(--cta);
			border-radius: 50%;
			z-index: 5;
			cursor: pointer;

			transform: scale(0);
		}
		.swiper-button-prev:after,
		.swiper-button-next:after {
			content: "";
			position: absolute;
			top: 0;
			left: 4px;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 25px;
			width: 25px;
			background: url("/images/arrow.svg");
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
		.swiper-button-prev:after {
			left: 0;
			right: 4px;
			transform: scale(-1, 1);
		}
		.swiper-button-prev {
			left: 0;
		}
		.swiper-button-next {
			right: 0;
		}
	}
}
.slider-Block.animate {
	.pushIn {
		h2 {
			animation-name: h2;
			animation-iteration-count: 1;
			animation-duration: 1s;
			animation-delay: 0;
			animation-fill-mode: forwards;
		}
		.singleSlide:after {
			animation-name: singleSlide;
			animation-iteration-count: 1;
			animation-duration: 2s;
			animation-delay: 0;
			animation-fill-mode: forwards;
		}
		@for $i from 1 through 10 {
			.swiper-slide:nth-child(#{$i}n) {
				.singleSlide {
					img,
					.content {
						animation-delay: #{0 + ($i * 0.3)}s;
					}
				}
				.singleSlide:after {
					animation-delay: #{0 + ($i * 0.3)}s;
				}
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			animation-name: slideButtons;
			animation-iteration-count: 1;
			animation-duration: 0.4s;
			animation-delay: 1s;
			animation-fill-mode: forwards;
		}
		.swiper-button-next {
			animation-delay: 1.2s;
		}
	}
}
@media only screen and (max-width: 768px) {
	.slider-Block {
		.pushIn {
			.singleSlide {
				.content {
					h4 {
						font-size: 22px;
					}
				}
			}
			.swiper-button-prev,
			.swiper-button-next {
				display: none;
			}
		}
	}
}

/* ----- Animations -------------------- */
@keyframes h2 {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes singleSlide {
	0% {
		top: 0;
	}
	100% {
		top: -100%;
	}
}
@keyframes slideButtons {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
</style>
