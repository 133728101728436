<template>
	<div class="text-Block" :class="{ animate: animate }" :id="'text-Block-' + id" v-if="loaded">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="pushIn">
						<h2>{{ $props.content.heading }}</h2>
						<h3 v-if="$props.content.subheading">{{ $props.content.subheading }}</h3>
						<p v-html="$props.content.text"></p>
						<a v-if="$props.content.buttonLink" :href="$props.content.buttonLink" target="_blank">
							<button class="mt-3">{{ $props.content.buttonText }}</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
	name: "Text Block",
	props: {
		content: Object,
	},
	setup() {
		/* ----- Data -------------------- */
		const loaded = ref(false);
		const animate = ref(false);
		const id = Math.floor(Math.random() * (999 - 1) + 1);

		/* ----- Methods -------------------- */
		const checkAnimation = () => {
			if (
				document.getElementById("text-Block-" + id).offsetTop - window.screen.height / 1.5 <
				window.scrollY
			) {
				animate.value = true;
			}
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;

			document.addEventListener("DOMContentLoaded", () => {
				checkAnimation();
			});
			document.addEventListener("scroll", () => {
				checkAnimation();
			});
		});

		return {
			loaded,
			animate,
			id,
			checkAnimation,
		};
	},
};
</script>

<style lang="scss" scoped>
.text-Block {
	background-color: var(--background-alt);
	padding: 110px 0;

	h2 {
		font-size: 40px;
		font-weight: 700;
		color: var(--text);
		margin: 0;
		margin-bottom: 20px;

		transform: translateY(50px);
		opacity: 0;
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: var(--text);
		margin: 0;

		transform: translateY(35px);
		opacity: 0;
	}
}
.text-Block.animate {
	h2 {
		animation-name: h2;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0;
		animation-fill-mode: forwards;
	}
	p {
		animation-name: p;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.3s;
		animation-fill-mode: forwards;
	}
}

/* ----- Animations -------------------- */
@keyframes h2 {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes p {
	0% {
		transform: translateY(35px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
