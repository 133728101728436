<template>
	<div class="textVideo-Block" :class="{ animate: animate }" :id="'textVideo-Block-' + id">
		<div class="container">
			<div class="row">
				<div class="col-md-6">
					<h2>{{ content.heading }}</h2>
					<p v-html="content.text"></p>
					<div v-if="content.buttons">
						<div v-if="content.buttons.length > 0">
							<a v-for="item in content.buttons" :key="item" :href="item.buttonLink" target="_blank">
								<button>{{ item.buttonText }}</button>
							</a>
						</div>
					</div>
				</div>
				<div class="col-md-6"></div>
			</div>
		</div>
		<div class="video" v-if="content.videoThumbail">
			<video controls controlsList="nodownload" :poster="content.videoThumbail" v-if="content.videoType">
				<source :src="content.videoURL" type="video/mp4" />
			</video>
			<video controls controlsList="nodownload" :poster="content.videoThumbail" v-else>
				<source :src="content.videoURL" type="video/mp4" />
			</video>
		</div>
		<div class="video 123" v-else>
			<video
				controls
				controlsList="nodownload"
				:poster="content.videoURL.replace('mp4', 'jpg')"
				v-if="content.videoType"
			>
				<source :src="content.videoURL" type="video/mp4" />
			</video>
			<video
				controls
				controlsList="nodownload"
				:poster="'https://cdn.hivebyups.com/ups/StreamingAssets/' + content.videoURL.replace('mp4', 'jpg')"
				v-else
			>
				<source :src="'https://cdn.hivebyups.com/ups/StreamingAssets/' + content.videoURL" type="video/mp4" />
			</video>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
	name: "Text Video Block",
	props: {
		content: Object,
	},
	setup() {
		/* ----- Data -------------------- */
		const loaded = ref(false);
		const animate = ref(false);
		const id = Math.floor(Math.random() * (999 - 1) + 1);

		/* ----- Methods -------------------- */
		const checkAnimation = () => {
			if (
				document.getElementById("textVideo-Block-" + id).offsetTop - window.screen.height / 1.5 <
				window.scrollY
			) {
				animate.value = true;
			}
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;

			document.addEventListener("DOMContentLoaded", () => {
				checkAnimation();
			});
			document.addEventListener("scroll", () => {
				checkAnimation();
			});
		});

		return {
			loaded,
			animate,
			id,
			checkAnimation,
		};
	},
};
</script>

<style lang="scss" scoped>
.textVideo-Block {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: calc(56.25vw / 2);
	background-color: var(--background-dark);

	h2 {
		font-size: 40px;
		font-weight: 700;
		color: var(--background);
		margin: 0;
		margin-bottom: 20px;

		transform: translateY(50px);
		opacity: 0;
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: var(--background);
		margin: 0;
		margin-bottom: 45px;

		transform: translateY(35px);
		opacity: 0;
	}
	button {
		transform: translateY(35px);
		opacity: 0;
	}
	.video {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: calc(50% - 15px);
		height: 100%;
		overflow: hidden;

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;

			opacity: 0;
		}
	}
	.video:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--hive);
		z-index: 2;
	}
}
.textVideo-Block.animate {
	h2 {
		animation-name: h2;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0;
		animation-fill-mode: forwards;
	}
	p {
		animation-name: p;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.3s;
		animation-fill-mode: forwards;
	}
	button {
		animation-name: button;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.6s;
		animation-fill-mode: forwards;
	}
	.video {
		video {
			animation-name: iframe;
			animation-iteration-count: 1;
			animation-duration: 1s;
			animation-delay: 0s;
			animation-fill-mode: forwards;
		}
	}
	.video:after {
		animation-name: video;
		animation-iteration-count: 1;
		animation-duration: 2s;
		animation-delay: 0s;
		animation-fill-mode: forwards;
	}
}
@media only screen and (max-width: 768px) {
	.textVideo-Block {
		height: auto;
		padding-top: calc(56.25% + 60px);
		padding-bottom: 60px;

		.video {
			width: 100%;
			height: calc(100% - 56.6%);
		}
		button {
			width: 100%;
			text-align: center;
		}
	}
}

/* ----- Animations -------------------- */
@keyframes h2 {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes p {
	0% {
		transform: translateY(35px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes button {
	0% {
		transform: translateY(35px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes video {
	0% {
		top: 100%;
	}
	50% {
		top: 0;
	}
	100% {
		top: -100%;
	}
}
@keyframes iframe {
	0% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
